import type { FunctionComponent } from 'react';
import type { BentoPieceRectangleFragment } from '../../../../graphql/queries/GetBentoPieceRectangle.graphql';
import type { BentoPieceSquareFragment } from '../../../../graphql/queries/GetBentoPieceSquare.graphql';
import type { BentoThreeFragment } from '../../../../graphql/queries/GetBentoThree.graphql';
import BentoPieceSquare from '../../pieces/bento-piece-square';
import { BentoRectangle } from '../../pieces/bento-rectangle';
import styles from './bento-three.module.scss';
export const BentoThree: FunctionComponent<Props> = ({
  pieces,
  rectangleLeft
}) => {
  const squareOne = pieces?.items[0] as BentoPieceSquareFragment;
  const squareTwo = pieces?.items[1] as BentoPieceSquareFragment;
  const rectangle = pieces?.items[2] as BentoPieceRectangleFragment;
  return <div className={styles.container} data-sentry-component="BentoThree" data-sentry-source-file="bento-three.tsx">
            <div className={`${styles.flexContainer} ${rectangleLeft ? styles.reverse : ''}`}>
                <div className={`${styles.boxFirst} ${rectangleLeft ? styles.reverse : ''}`}>
                    <div className={styles.boxFirstContainer}>
                        <div className={styles.boxOneContent}>
                            <BentoPieceSquare square={squareOne} data-sentry-element="BentoPieceSquare" data-sentry-source-file="bento-three.tsx" />
                        </div>

                        <div className={styles.boxTwoContent}>
                            <BentoPieceSquare square={squareTwo} data-sentry-element="BentoPieceSquare" data-sentry-source-file="bento-three.tsx" />
                        </div>
                    </div>
                </div>
                <div className={`${styles.boxSecond} ${rectangleLeft ? styles.reverse : ''}`}>
                    <div className={styles.boxSecondContainer}>
                        <BentoRectangle vertical headingMd={rectangle?.headingMd} headingImage={rectangle?.headingImage} headingImageAlignment={rectangle?.headingImageAlignment} headingColor={rectangle?.headingColor} copy={rectangle?.copy} showFdicVisaImage={rectangle?.showFdicVisaImage} button={rectangle?.button} backgroundType={rectangle?.backgroundType} backgroundColor={rectangle?.backgroundColor} image={rectangle?.image} backgroundImage={rectangle?.backgroundImage} backgroundImageMobile={rectangle?.backgroundImageMobile} iconTextList={rectangle?.iconTextList} data-sentry-element="BentoRectangle" data-sentry-source-file="bento-three.tsx" />
                    </div>
                </div>
            </div>
        </div>;
};
export default BentoThree;
type Props = Omit<BentoThreeFragment, 'sys' | 'type'> & {
  index?: number;
};